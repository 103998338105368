import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

function initSwiper() {
  const newsCarousels = document.querySelectorAll(
    '.news-slider-block__carousel'
  );

  if (!newsCarousels) {
    return;
  }

  newsCarousels.forEach(function (newsCarousel) {
    new Swiper(newsCarousel, {
      draggable: true,
      slidesPerView: '1.2',
      spaceBetween: 16,
      pagination: {
        el: newsCarousel
          .closest('.news-slider-block__carousel')
          .querySelector('.swiper-pagination'),
        clickable: true,
        type: 'fraction',
      },
      navigation: {
        nextEl: newsCarousel
          .closest('.news-slider-block__carousel')
          .querySelector('.swiper-button-next'),
        prevEl: newsCarousel
          .closest('.news-slider-block__carousel')
          .querySelector('.swiper-button-prev'),
      },
      breakpoints: {
        600: {
          slidesPerView: '1.2',
        },
        769: {
          slidesPerView: '2.4',
        },
        1200: {
          slidesPerView: '3.4',
        },
      },
    });
  });
}

domReady(() => {
  initSwiper();

  function moveButton() {
    const btnArrow = document.querySelector('.news-slider-block__btn--arrow');
    const swiper = document.querySelector('.news-slider-block__carousel');
    const titleInner = document.querySelector(
      '.news-slider-block__title-inner'
    );

    if (window.innerWidth < 1200) {
      if (btnArrow && swiper) {
        swiper.appendChild(btnArrow);
      }
    } else {
      titleInner.appendChild(btnArrow);
    }
  }

  // Initial check
  moveButton();

  // Check on resize
  window.addEventListener('resize', moveButton);
});
